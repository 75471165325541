import { FC, FormEvent, useCallback, useEffect, useState } from "react";

import {
  Column,
  Container,
  MoneyComponent,
  SelectComponent,
  DateComponent,
  MessageComponent,
  utils,
} from "@truenorthmortgage/olympus";
import {
  formatNumberToMoneyFormat,
  notify,
  notifyErrors,
  unitsDividedByMultiplier,
  unitsMultipliedByMultiplier,
  convertMoneyStringToInteger,
} from "../../../utils";
import { ERROR } from "../../../constants";
import { SERVICES } from "../../../services";
import { ActionQueueService } from "../../../services/action-queue-service";
import {
  ActionQueueCancelForm,
  ActionQueueWithdrawForm,
  DefaultResponseRequest,
  WithdrawAccountResponse,
} from "../../../models/schemas/request-schema";
import Loading from "../../Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type WithdrawModalProps = {
  callback?: any;
  account_info?: any;
  cancel_request?: any;
  action_queue_withdraw_object?: any;
};

const WithdrawModal: FC<WithdrawModalProps> = ({
  account_info = null,
  callback = null,
  cancel_request = null,
  action_queue_withdraw_object = null,
}) => {
  const [loading, setLoading] = useState(false);
  const actionQueueService = utils.injection.useInjection<ActionQueueService>(
    SERVICES.ActionQueueService
  );

  const [errors, setErrors] = useState({
    funding_from_bank_account_uuid: false,
    funding_date: false,
    source_of_funds: false,
    amount: false,
    to_external_bank_account_uuid: false,
  });

  const [amount, setAmount] = useState(0);
  const [investmentAccountUuid] = useState(account_info?.uuid);
  const [investorAccountUUID] = useState(account_info?.investorAccount?.uuid);
  const [bankAccountUuid, setBankAccountUUID] = useState("select");
  const [requestWithdrawDate, setRequestWithdrawDate] = useState("");
  const [sourceOfFunds, setSourceOfFunds] = useState("");
  const [actionQueueUUID, setActionQueueUUID] = useState("");
  const [accountCurrentBalance] = useState(
    account_info?.accountingAccount?.balance
  );
  const [accountBalanceError, setAccountBalanceError] = useState(false);

  const dateCallback = (val: any) => {
    if (val) {
      setRequestWithdrawDate(val);
    }
  };

  const amountCallback = (amount: any) => {
    setAmount(convertMoneyStringToInteger(amount));
  };

  const bankAccountCallback = (val: any) => {
    setBankAccountUUID(val);
  };

  // Reset the form make sure  !
  const resetForm = () => {
    setAmount(0);
    setRequestWithdrawDate("");
    setSourceOfFunds("");
    setBankAccountUUID("select");
    setAccountBalanceError(false);
    cleanErrors();
  };

  const cleanErrors = () => {
    setErrors({
      funding_from_bank_account_uuid: false,
      funding_date: false,
      source_of_funds: false,
      amount: false,
      to_external_bank_account_uuid: false,
    });
  };

  const investor_bank_accounts =
    account_info?.investorAccount?.bank_accounts?.map((bank_account: any) => {
      return (
        <option
          id={bank_account.uuid}
          key={bank_account.uuid}
          value={bank_account.uuid}
        >
          {bank_account?.bank_number + " " + bank_account?.account_number}
        </option>
      );
    });

  const submitCancelRequest = useCallback(
    (event: FormEvent) => {
      const form = {
        action_queue_uuid: actionQueueUUID,
      } as ActionQueueCancelForm;

      event.preventDefault();
      setLoading(true);
      actionQueueService
        .cancelActionQueueRequest(form)
        .then((data: DefaultResponseRequest) => {
          if (true === data.success) {
            notify(data.message as string, "success");
            resetForm();
            callback();
          } else {
            if (data.errors) {
              if (data?.errors?.request_processed === true) {
                callback();
              }
            }
            if (data.message) {
              notify(data.message as string, "error");
            }
            setErrors(data.errors);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [actionQueueService, actionQueueUUID]
  );

  const submitWithdrawRequest = useCallback(
    (event: FormEvent) => {
      const form = {
        withdraw: {
          from_investment_account_uuid: investmentAccountUuid,
          to_external_bank_account_uuid: bankAccountUuid,
          amount: amount ? amount.toString() : 0,
          transaction_date: requestWithdrawDate,
          investor_account_uuid: investorAccountUUID,
        },
      } as ActionQueueWithdrawForm;

      event.preventDefault();
      setLoading(true);
      actionQueueService
        .sendWithdrawRequest(form)
        .then((data: WithdrawAccountResponse) => {
          if (true === data.success) {
            notify(data.message as string, "success");
            resetForm();
            callback();
          } else {
            if (data.errors) {
              notifyErrors(data.errors);
            }
            if (data.message) {
              notify(data.message as string, "error");
            }
            setErrors(data.errors);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [
      actionQueueService,
      location,
      bankAccountUuid,
      investmentAccountUuid,
      investorAccountUUID,
      requestWithdrawDate,
      amount,
      sourceOfFunds,
    ]
  );

  // If we are canceling the request set the values to match the object !
  useEffect(() => {
    if (cancel_request === true) {
      setAmount(Number(action_queue_withdraw_object?.amount));
      setRequestWithdrawDate(action_queue_withdraw_object?.transaction_date);
      setBankAccountUUID(action_queue_withdraw_object?.bank_account?.uuid);
      setSourceOfFunds(action_queue_withdraw_object?.source_of_funds);
      setActionQueueUUID(action_queue_withdraw_object?.uuid);
    } else {
      resetForm();
    }
  }, [action_queue_withdraw_object, cancel_request]);

  useEffect(() => {
    if (cancel_request === true) {
      setRequestWithdrawDate(action_queue_withdraw_object?.transaction_date);
    }
  }, [requestWithdrawDate, cancel_request]);

  useEffect(() => {
    if (cancel_request === true) {
      setAmount(action_queue_withdraw_object?.amount);
    }
  }, [amount, cancel_request]);

  // For some reason this allows the olympus select component to be reset !
  useEffect(() => {
    setBankAccountUUID(bankAccountUuid);
  }, [bankAccountUuid]);

  return (
    <>
      <Container
        className={"generic-modal-container action-queue-fund-container"}
      >
        <Column columnStyle={"full create-bank-account-form"}>
          <div className="center">
            <h2>Account Withdrawal Request</h2>
          </div>
        </Column>

        <Column columnStyle={"flex-column"}>
          {account_info?.type === "tfsa" ? (
            <>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className={"tfsa-warning"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className={"inner-wrap"}>
                    <FontAwesomeIcon
                      icon="warning"
                      style={{
                        marginTop: "10px",
                        fontSize: "18px",
                        marginRight: "10px",
                        marginBottom: "10px",
                        color: "#c90000",
                      }}
                    />
                    <p style={{ fontWeight: "bold" }}>Warning</p>
                  </div>
                </div>
                <div className={"info-container"}>
                  Are you sure you want to withdraw these funds from your TFSA?
                  This could affect your contribution limits for this year if
                  you wish to re-contribute any of the withdrawn amount.
                  {"  "}
                </div>
                <div className={"info-container"} style={{ marginTop: "10px" }}>
                  <strong>
                    Please confirm with the CRA if you are unsure how this will
                    impact your contribution limits.
                  </strong>
                </div>
              </div>
            </>
          ) : (
            <div></div>
          )}
        </Column>

        <Column columnStyle={"flex-column"}>
          <Column columnStyle="full">
            <Column columnStyle="side-by-side-element no-left-padding">
              <label>Funding Investment Account</label>
              {account_info?.type_label + " " + account_info?.morcado_id}
            </Column>
          </Column>
          <Column columnStyle="full">
            <Column columnStyle="side-by-side-element">
              <label>Cash Balance</label>$
              {formatNumberToMoneyFormat(
                unitsDividedByMultiplier(
                  account_info?.accountingAccount?.balance,
                  100
                )
              )}
            </Column>
          </Column>
        </Column>
        <Column columnStyle={"flex-column"}>
          <Column columnStyle="side-by-side-element funding-money">
            <label>Withdrawal Amount</label>
            <MoneyComponent
              id="transit_number"
              key="transit_number"
              disabled={
                (loading ? true : false) || (cancel_request ? true : false)
              }
              value={amount ? amount?.toString() : ""}
              onChange={amountCallback}
              error={errors?.amount || accountBalanceError}
            />
          </Column>
          <Column columnStyle="side-by-side-element">
            <SelectComponent
              label="To Bank Account"
              id="from_bank_account"
              key="from_bank_account"
              value={bankAccountUuid}
              defaultValue={bankAccountUuid}
              disabled={
                (loading ? true : false) || (cancel_request ? true : false)
              }
              onChange={bankAccountCallback}
              error={errors?.to_external_bank_account_uuid}
            >
              <option value="select">Select</option>
              {investor_bank_accounts}
            </SelectComponent>
          </Column>
        </Column>
        <Column columnStyle={"flex-column"}>
          <Column columnStyle="side-by-side-element no-left-padding-date-component">
            <label>Requested Transaction Date</label>
            <div className={"date-icon-wrap"}>
              <i className="fa fa-calendar"></i>
              <DateComponent
                id="requested_transaction_date"
                key="requested_transaction_date"
                className={"no-left-padding"}
                onChange={dateCallback}
                minDate={
                  cancel_request ? new Date(requestWithdrawDate) : new Date()
                }
                disabled={
                  (loading ? true : false) || (cancel_request ? true : false)
                }
                defaultValue={requestWithdrawDate}
                value={requestWithdrawDate}
                error={errors?.funding_date}
              />
            </div>
          </Column>
          <Column columnStyle={"side-by-side-element"}></Column>
        </Column>

        {cancel_request === true ? (
          <div></div>
        ) : (
          <Column columnStyle={"flex-column no-left-padding"}>
            <div className={"info-container"}>
              All fields are required. We endeavour to process transactions on
              your requested transaction date, but cannot guarantee the
              transaction date. Withdraw request must be a minimum of $1000.00.
            </div>
          </Column>
        )}

        {cancel_request === true ? (
          <Column columnStyle="flex-column small-top-margin no-left-padding no-right-padding">
            <button
              key="cancel_fund_request"
              type="button"
              className="button submit-button no-right-margin"
              style={{ marginRight: "auto" }}
              onClick={callback}
            >
              <i
                style={{ marginRight: "10px" }}
                className="fa-solid fa-chevron-left"
              ></i>
              Back
            </button>

            <button
              key="send_fund_request"
              type="button"
              className="button submit-button no-right-margin button-red"
              style={{ marginLeft: "auto" }}
              onClick={submitCancelRequest}
              disabled={loading ? true : false}
            >
              {loading === true ? (
                <Loading
                  style={{
                    height: 40,
                    marginTop: -30,
                    position: "relative",
                    top: 14,
                  }}
                  color={"white"}
                />
              ) : (
                " Cancel Request"
              )}
            </button>
          </Column>
        ) : (
          <Column columnStyle="flex-column small-top-margin no-left-padding no-right-padding">
            <button
              key="cancel_fund_request"
              type="button"
              className="button submit-button no-right-margin button-red"
              onClick={callback}
              style={{ marginRight: "auto" }}
            >
              Cancel
            </button>

            <button
              key="send_fund_request"
              type="button"
              className="button submit-button no-right-margin button-green"
              style={{ marginLeft: "auto" }}
              onClick={submitWithdrawRequest}
              disabled={loading ? true : false}
            >
              {loading === true ? (
                <Loading
                  style={{
                    height: 40,
                    marginTop: -30,
                    position: "relative",
                    top: 14,
                  }}
                  color={"white"}
                />
              ) : (
                " Send Request"
              )}
            </button>
          </Column>
        )}
      </Container>
    </>
  );
};

export default WithdrawModal;
