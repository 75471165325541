import { Session } from "../models/auth/session";
import { injectable } from "inversify";
import { HttpService } from "./http.service";
import { Location } from "history";
import { setSession } from "../reducers/session.reducer";
import { NavigateFunction } from "react-router";
import { FormSchema } from "../models/schemas/form-schema";
import { ValidationError } from "../errors/validation-error";
import { Dispatch } from "@reduxjs/toolkit";
import { notify } from "../utils";

@injectable() // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export class AuthService {
  private _token: string | null = null;
  private _impersonatedToken: string | null = null;
  private session: Session | null = null;

  get token() {
    const localStorageToken = localStorage.getItem("token");
    if (!this._token && localStorageToken) {
      this._token = localStorageToken;
    }
    return this._token;
  }

  set token(token: string | null) {
    this._token = token;
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }

  get impersonatedToken() {
    const localStorageToken = localStorage.getItem("impersonatedToken");
    if (!this._impersonatedToken && localStorageToken) {
      this._impersonatedToken = localStorageToken;
    }
    return this._impersonatedToken;
  }

  set impersonatedToken(token: string | null) {
    this._impersonatedToken = token;
    if (token) {
      localStorage.setItem("impersonatedToken", token);
    } else {
      localStorage.removeItem("impersonatedToken");
    }
  }

  async logOut(navigate: NavigateFunction) {
    this.impersonatedToken = null;
    this.token = null;
    localStorage.removeItem("token");
    localStorage.removeItem("account_id");
    localStorage.removeItem("investment_accounts");
    notify("Logout Successful" as string, "success");
    navigate("/");
  }

  getAuthorizationHeaders(
    { contentType }: { contentType: string | null } = {
      contentType: "application/json",
    }
  ): Record<string, string> {
    const headers: Record<string, string> = contentType
      ? {
          "Content-Type": contentType,
        }
      : {};

    if (this.token) {
      headers["Authorization"] = `Bearer ${
        this.impersonatedToken ? this.impersonatedToken : this.token
      }`;
    }

    return headers;
  }

  async getSession(location: Location): Promise<Session> {
    if (!this.session && this.token) {
      await this.requestSession();
    } else if (!this.token && location.hash !== "/login") {
      this.resetSession();
    }
    return this.session!;
  }

  getSessionSync(): Session | null {
    return this.session;
  }

  private async requestSession() {
    const [status, userItems] = await HttpService.executeFetchRequest(
      "/user/info",
      this.getAuthorizationHeaders()
    );
    this.validateRequest(status, userItems);

    const isAvailable = true;
    this.session = {
      initial_uri: userItems.initial_uri,
      isAvailable,
      user: {
        id: userItems.id,
        name: `${userItems.firstname} ${userItems.lastname}`,
        email: userItems.email,
        investment_accounts: userItems.investment_accounts,
      },
    };

    this.investmentAccounts = JSON.stringify(userItems.investment_accounts);
  }

  async getUserSettignsForm(): Promise<FormSchema> {
    const [status, settingsForm] = await HttpService.executeFetchRequest(
      "/users/settings",
      this.getAuthorizationHeaders()
    );
    this.validateRequest(status, settingsForm);
    return settingsForm;
  }

  validateRequest(status: number, json: any) {
    if (
      status === 401 ||
      (status !== 200 && json.type === "Firebase\\JWT\\ExpiredException")
    ) {
      this.resetSession();
    } else if (status === 422) {
      throw new ValidationError(json);
    } else if (
      status !== 200 &&
      json.type !== "yii\\web\\BadRequestHttpException" &&
      json.type !== "yii\\web\\NotFoundHttpException"
    ) {
      throw new Error(json.message);
    }
  }

  resetSession() {
    this.token = null;
    this.impersonatedToken = null;
    window.location.replace("/login");
  }

  async logIn(token: string, dispatch: Dispatch<any>) {
    this.token = token;
    this.impersonatedToken = null;
    await this.requestSession();
    dispatch(setSession(this.session));
  }

  set investmentAccounts(investment_accounts: any) {
    if (investment_accounts) {
      localStorage.setItem("investment_accounts", investment_accounts);
    } else {
      localStorage.removeItem("investment_accounts");
    }
  }
}
