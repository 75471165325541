import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo-morcado.png";
import { utils } from "@truenorthmortgage/olympus";
import { AuthService } from "../services/auth.service";
import { SERVICES } from "../services";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavComponent = () => {
  const navigate = useNavigate();
  const authService = utils.injection.useInjection<AuthService>(
    SERVICES.AuthService
  );

  const closeNav = () => {
    const items = document.getElementById("nav-items");
    if (items) items.className = "";
  };

  const toggleNav = () => {
    const items = document.getElementById("nav-items");
    if (items) items.className = items.className === "open" ? "" : "open";
  };

  const logout = () => {
    authService.logOut(navigate);
  };

  const investment_accounts =
    localStorage.getItem("investment_accounts") !== null
      ? JSON.parse(localStorage.getItem("investment_accounts") as string)
      : null;

  const displayVersion = "Morcado Investor Portal " + window.REACT_APP_VERSION;

  return (
    <nav id="nav-bar">
      <div className="nav-bar">
        <div className="nav-bar-contents">
          <div></div>
          <a href="/dashboard" title={displayVersion}>
            <img src={logo} className="app-logo" alt="logo" />
          </a>
          <div className="nav-toggle" onClick={toggleNav}>
            <i className="fa fa-bars primary"></i>
          </div>
        </div>
      </div>
      <div id="nav-items" onClick={closeNav}>
        <div onClick={closeNav} className="close-button">
          <i className="fa fa-close"></i>
        </div>
        <ul>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          {investment_accounts &&
            Array.isArray(investment_accounts) &&
            investment_accounts.length > 1 && (
              <li key="investor-accounts" className="primary">
                My Accounts
                {investment_accounts.map((investment_account: any) => {
                  return (
                    <div
                      key={"div-investment-account-" + investment_account.uuid}
                      style={{ textAlign: "left", marginLeft: "4em" }}
                    >
                      <Link
                        key={
                          "div-link-investment-account-" +
                          investment_account.uuid
                        }
                        to={`/investment-account/${investment_account.uuid}`}
                        reloadDocument={true}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          style={{
                            fontSize: "0.4em",
                            marginRight: "0.4em",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        {investment_account?.type_label ?? "Cash"}{" "}
                        <span style={{ fontSize: "0.6em" }}>
                          ({investment_account.morcado_id})
                        </span>
                      </Link>
                    </div>
                  );
                })}
              </li>
            )}
          {investment_accounts &&
            Array.isArray(investment_accounts) &&
            investment_accounts.length === 1 && (
              <li>
                <Link to={"/investment-account/" + investment_accounts[0].uuid}>
                  My Account
                </Link>
              </li>
            )}
          <li>
            <button className="link-button" onClick={() => logout()}>
              Logout
            </button>
          </li>
        </ul>
        <div className="external-links">
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.morcado.ca/utility/privacy-policy"
              >
                Privacy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="nav-mask" onClick={closeNav} />
    </nav>
  );
};

export default NavComponent;
