import { useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { SERVICES } from "../services";
import { DashboardPageService } from "../services/dashboard-page.service";
import { useNavigate } from "react-router-dom";
import { formatNumberToMoneyFormat, unitsDividedByMultiplier } from "../utils";

import {
  utils,
  ActionCards,
  ActionCard,
  Table,
  BasicRow,
  TextCell,
  CellHeader,
  RawCell,
  ActionButton,
  ActionButtonsCell,
  Notifications,
} from "@truenorthmortgage/olympus";
import Loading from "../components/Loading";
import NavComponent from "../components/NavComponent";
import NotificationComponent from "../components/NotificationComponent";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
import { PhoneLink } from "../components";

const Dashboard = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const optionLabel = intl.formatMessage({ id: "Options" });

  const [userName, setUserName] = useState("");
  const [greeting] = useState("Hello " + userName);
  const [isLoading, setIsLoading] = useState(true);

  const dashboardService = utils.injection.useInjection<DashboardPageService>(
    SERVICES.DashboardPageService
  );

  // Set the states for dahsboard data
  const [cashBalance, setCashBalance] = useState(0);
  const [totalInvested, setTotalInvested] = useState(0);
  const [totalInvestments, setTotalInvestments] = useState(0);
  const [totalPendingCredits, setTotalPendingCredits] = useState(0);
  const [totalPendingDebits, setTotalPendingDebits] = useState(0);
  const [investmentAccounts, setInvestmentAccounts] = useState([]) as any;
  const [investorAccountUuid, setInvestorAccountUuid] = useState("");
  const [investorAccountFriendlyId, setInvestorAccountFriendlyId] =
    useState("");

  // Grab the Dashboard Data !
  // Link this data to values for display !
  useEffect(() => {
    dashboardService
      .getDashboardData()
      .then((data) => {
        const fullname = (data?.firstname ?? "") + " " + (data?.lastname ?? "");
        setUserName(fullname);
        setIsLoading(false);
        setCashBalance(data?.cash_balance);
        setTotalInvested(data?.total_invested);
        setTotalInvestments(data?.total_investments);
        setInvestmentAccounts(data?.investment_accounts);
        setInvestorAccountUuid(data?.investor_accounts[0]?.uuid);
        setInvestorAccountFriendlyId(data?.investor_accounts[0]?.friendly_id);
        setTotalPendingCredits(data?.total_pending_credits);
        setTotalPendingDebits(data?.total_pending_debits);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [userName]);

  const investorAccountDetailsLabel = intl.formatMessage({
    id: "Investor Account Details",
  });
  const investmentListingsLabel = intl.formatMessage({
    id: "Investment Listings",
  });

  const cards = useMemo(
    () => [
      {
        label: investmentListingsLabel,
        icon: "file-alt",
        path: "/investment-listings",
      },
      {
        label: investorAccountDetailsLabel,
        icon: "user",
        path: "/investor-account-details/" + investorAccountUuid,
      },
    ],
    [investmentListingsLabel, investorAccountDetailsLabel, investorAccountUuid]
  );

  const viewInvestmentAccount = (uuid: any) => {
    navigate("/investment-account/" + uuid);
  };

  const breadcrumbs = [{ label: "Dashboard", path: "/dashboard" }];

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="dashboard-container">
        <NavComponent />
      </div>
      <div id="main" className="dashboard-content">
        <Notifications
          timeout={15000}
          customNotificationElement={<NotificationComponent />}
        />
        <div className="main-header">
          <BreadcrumbsComponent items={breadcrumbs} />s
        </div>
        <div className="main-content">
          <div className="dashboard">
            <h1
              style={{ textTransform: "capitalize", marginBottom: "3px" }}
              className={"mobile-header"}
            >
              {greeting} <span data-dd-privacy="mask">{userName}</span>
            </h1>
            <h3>
              Personal Investor Account{" "}
              <span data-dd-privacy="mask">{investorAccountFriendlyId}</span>
            </h3>

            <h2>{optionLabel}</h2>
            <div className="divider" />
            <ActionCards>
              {cards.map(({ label, icon, path }) => (
                <ActionCard key={icon} path={path}>
                  <i className={"fa fa-" + icon}></i>
                  <br />
                  <span>{label}</span>
                </ActionCard>
              ))}
            </ActionCards>

            <h2>Overview</h2>
            <div className="divider" />
            <Table className="primary overview-table table-no-display-block">
              <BasicRow>
                <TextCell>Total Invested</TextCell>
                <TextCell>
                  <span className={"data-dog-wrap"} data-dd-privacy="mask">
                    ${formatNumberToMoneyFormat(totalInvested)}
                  </span>
                </TextCell>
              </BasicRow>
              <BasicRow>
                <TextCell>Cash Balance</TextCell>
                <TextCell>
                  <span className={"data-dog-wrap"} data-dd-privacy="mask">
                    ${formatNumberToMoneyFormat(cashBalance)}
                  </span>
                </TextCell>
              </BasicRow>
              <BasicRow>
                <TextCell>Total Investments</TextCell>
                <TextCell>
                  <span className={"data-dog-wrap"} data-dd-privacy="mask">
                    {totalInvestments}
                  </span>
                </TextCell>
              </BasicRow>
              <BasicRow>
                <TextCell>Total Pending Debit</TextCell>
                <TextCell>
                  <span className={"data-dog-wrap"} data-dd-privacy="mask">
                    ${formatNumberToMoneyFormat(totalPendingDebits)}
                  </span>
                </TextCell>
              </BasicRow>
              <BasicRow>
                <TextCell>Total Pending Credit</TextCell>
                <TextCell>
                  <span className={"data-dog-wrap"} data-dd-privacy="mask">
                    ${formatNumberToMoneyFormat(totalPendingCredits)}
                  </span>
                </TextCell>
              </BasicRow>
            </Table>

            <h2>Investment Accounts</h2>
            <div className="divider" />
            <Table className="primary investment-table">
              <CellHeader className="table-header">
                <RawCell>Account</RawCell>
                <RawCell className={"hide-on-mobile"}>Type</RawCell>
                <RawCell>Investments</RawCell>
                <RawCell>Cash Balance</RawCell>
                <RawCell></RawCell>
              </CellHeader>

              {investmentAccounts
                ?.filter(
                  (investment_account: any) =>
                    investment_account?.status === "active"
                )
                .map((investment_account: any) => (
                  <BasicRow
                    className="table-rows"
                    key={investment_account?.uuid}
                  >
                    <TextCell className="white-row">
                      <span
                        data-dd-privacy="mask"
                        className={" dashboard-account-nickname"}
                      >
                        {investment_account?.type_label + " "}
                        <span
                          data-dd-privacy="mask"
                          className={"user-nickname"}
                        >
                          {investment_account?.user_nickname
                            ? "  - " + investment_account.user_nickname
                            : " "}
                        </span>
                      </span>
                      <span data-dd-privacy="mask" className={"morcado-id"}>
                        {investment_account?.morcado_id
                          ? investment_account?.morcado_id
                          : " "}
                      </span>
                    </TextCell>
                    <TextCell
                      data-dd-privacy="mask"
                      colSpan={1}
                      className="white-row hide-on-mobile"
                    >
                      <span data-dd-privacy="mask" className={"capitalize"}>
                        {investment_account?.type_label}
                      </span>
                    </TextCell>
                    <TextCell data-dd-privacy="mask" className="white-row">
                      <span data-dd-privacy="mask" className="black-text">
                        $
                        {formatNumberToMoneyFormat(
                          investment_account?.sum_total_investment_account
                        )}
                      </span>
                      <span>
                        {investment_account?.active_investments} Investments
                      </span>
                    </TextCell>
                    <TextCell className="white-row">
                      <span className={"data-dog-wrap"} data-dd-privacy="mask">
                        $
                        {formatNumberToMoneyFormat(
                          unitsDividedByMultiplier(
                            investment_account?.balance,
                            100
                          )
                        )}
                      </span>
                    </TextCell>
                    <ActionButtonsCell className="white-row">
                      <ActionButton
                        id={"cancel"}
                        key={"cancel-"}
                        callback={() =>
                          viewInvestmentAccount(investment_account?.uuid)
                        }
                      >
                        Details
                      </ActionButton>
                    </ActionButtonsCell>
                  </BasicRow>
                ))}
            </Table>

            <div className="footer-content"></div>
          </div>
        </div>
        <div className="footer">
          <div className="footer__phone-link">
            Need help?
            <PhoneLink />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
