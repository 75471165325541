import { FC, useEffect, useState } from "react";
import {
  ActionButton,
  SelectComponent,
  DateComponent,
  Table,
  TextCell,
  ActionButtonsCell,
  CellHeader,
  RawCell,
  BasicRow,
  utils,
} from "@truenorthmortgage/olympus";
import KratosPagination from "./KratosPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatNumber,
  formatNumberToMoneyFormat,
  unitsDividedByMultiplier,
} from "../utils";
import { TransactionService } from "../services/transaction-service";
import { SERVICES } from "../services";
import { CSVDownload } from "react-csv";
import KratosModal from "./Modals/KratosModal";

export type TransactionProps = {
  range?: any;
  date_from?: any;
  date_to?: any;
  transactions?: any;
  investment_account?: any;
  friendlly_id?: any;
};

const Transactions: FC<TransactionProps> = ({
  range = null,
  date_from = null,
  date_to = null,
  transactions = null,
  investment_account = null,
  friendlly_id = null,
}) => {
  // Csv Headers
  const headers = [
    "Posted Date",
    "Effective Date",
    "Type",
    "Status",
    "Description",
    "Amount",
  ];

  const transactionService = utils.injection.useInjection<TransactionService>(
    SERVICES.TransactionService
  );

  // Convert the original data to the desired type
  /* eslint-disable */
  interface convertedData {
    date: any;
    type: any;
    description: any;
    amount: any;
  }
  /* eslint-enable */

  const dateTime = new Date();
  const formattedDate = dateTime.toISOString().slice(0, 10);

  const [initiateDownload, setInitiateDownload] = useState(false);
  const [currentDate] = useState(new Date().toJSON().slice(0, 10));
  const [dateTo, setDateTo] = useState(date_to);
  const [dateFrom, setDateFrom] = useState(date_from);
  const [dateRange, setRange] = useState(range);
  const [csvData, setCsvData] = useState([[]]) as any;
  const [transactionData, setTransactionData] = useState(transactions);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [csvFileName] = useState(
    friendlly_id + "_transactions_" + formattedDate
  );

  const downloadCsvCallback = () => {
    const submitValues = {
      uuid: investment_account ?? null,
      currentDate: currentDate ?? null,
      dateTo: dateTo ?? null,
      dateFrom: dateFrom ?? null,
      quick_date_range: dateRange ?? null,
    };
    transactionService
      .getCSV({ TransactionFormData: submitValues })
      .then((data) => {
        const formattedData = [];
        for (const transaction of data?.transactions ?? []) {
          let amount = "";

          if (transaction?.debit_amount !== null) {
            amount = "- " + transaction?.debit_amount;
          }

          if (transaction?.credit_amount !== null) {
            amount = transaction?.credit_amount;
          }

          if (transaction.description.startsWith("Revert ") === true) {
            transaction.type_label = "Revert " + transaction.type_label;
          }

          // Access transaction properties here
          formattedData.push([
            transaction?.posted_date,
            transaction?.effective_date,
            transaction?.type_label,
            transaction?.status_label,
            transaction?.description,
            "$" + amount,
          ]);
        }

        setCsvData(formattedData);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (csvData.length > 0 && csvData[0]?.length > 0) {
      setInitiateDownload(true);
    }
  }, [csvData]);

  useEffect(() => {
    // Enables user to download data more than once
    if (initiateDownload) {
      setInitiateDownload(false);
    }
  }, [initiateDownload]);

  const quick_date_range_options = [
    {
      value: "1-month",
      label: "1 month",
    },
    {
      value: "3-months",
      label: "3 months",
    },
    {
      value: "6-months",
      label: "6 months",
    },
    {
      value: "1-year",
      label: "1 year",
    },
    {
      value: "all",
      label: "All",
    },
  ].map((options: any) => (
    <option
      id={options.value}
      key={"quick-date-range-" + options.value}
      className="capitalize"
      value={options.value}
    >
      {options.label}
    </option>
  ));

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const closeIsDetailsOpen = () => {
    setIsDetailsOpen(false);
  };

  const [transactionModalData, setTransactionModalData] = useState({
    effective_date: "",
    posted_date: "",
    status_label: "",
    type_label: "",
    friendly_id: "",
    debit_amount: "",
    credit_amount: "",
    units_multiplier: "",
    income_total: "",
    ownership_certificates: [],
    investment_interest_rate: "",
    servicing_rate: "",
  });

  const filterTransaction = () => {
    if (dateRange === null && dateTo === null && dateFrom === null) {
      return;
    }

    const submitValues = {
      investment_account_uuid: investment_account ?? null,
      date_from: dateFrom ?? null,
      date_to: dateTo ?? null,
      quick_date_range: dateRange ?? null,
      page: page ?? 1,
    };
    transactionService
      .updateTransactionData({ transactionUpdateForm: submitValues })
      .then((data) => {
        setTransactionData(data);
        setPage(data?.pagination?.page ?? 1);
        setTotalPages(data?.pagination?.page_count);
      })
      .catch(() => {});
  };

  useEffect(() => {
    filterTransaction();
  }, [dateRange, dateTo, dateFrom]);

  const updateModalData = (val: any) => {
    const submitValues = {
      uuid: val ?? null,
    };
    transactionService
      .getTransactionData({ transactionForm: submitValues })
      .then((data) => {
        setTransactionModalData({
          effective_date:
            data?.transaction_details?.journal_entry_item?.effective_date,
          posted_date:
            data?.transaction_details?.journal_entry_item?.posted_date,
          status_label:
            data?.transaction_details?.journal_entry_item?.status_label,
          type_label: data?.transaction_details?.investment?.type_label,
          friendly_id: data?.transaction_details?.investment?.friendly_id,
          debit_amount:
            data?.transaction_details?.journal_entry_item?.debit_amount,
          credit_amount:
            data?.transaction_details?.journal_entry_item?.credit_amount,
          units_multiplier:
            data?.transaction_details?.investment?.units_multiplier,
          income_total:
            data?.transaction_details?.journal_entry_item?.debit_amount ??
            data?.transaction_details?.journal_entry_item?.credit_amount,
          ownership_certificates:
            data?.transaction_details?.ownership_certificates,
          investment_interest_rate:
            data?.transaction_details?.investment?.interest_rate,
          servicing_rate: data?.transaction_details?.investment?.servicing_rate,
        });
        setIsDetailsOpen(true);
      })
      .catch(() => {});
  };

  // Handle the Pagination
  const handlePagination = (val: any) => {
    const submitValues = {
      investment_account_uuid: investment_account ?? null,
      date_from: dateFrom ?? null,
      date_to: dateTo ?? null,
      quick_date_range: dateRange ?? null,
      page: val ?? null,
    };
    transactionService
      .updateTransactionData({ transactionUpdateForm: submitValues })
      .then((data) => {
        setTransactionData(data);
        setPage(val);
        setTotalPages(data?.pagination?.page_count);
      })
      .catch(() => {});
  };

  return (
    <>
      <KratosModal is_open={isDetailsOpen} callback={closeIsDetailsOpen}>
        <div className="purchase-order-modal">
          <h1 className="main-header-modal-header">Interest Income Details</h1>
          <div className="modal-content">
            <div className="separator">
              <div className="left-side-modal">
                <div className="data-wrap">
                  <div className="modal-label">Effective Date</div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{transactionModalData?.effective_date}</div>
                    {transactionModalData?.posted_date !==
                      transactionModalData?.effective_date && (
                      <div>
                        <div
                          style={{ marginTop: "5px" }}
                          className="modal-label"
                        >
                          Posted Date
                        </div>
                        <div style={{ fontStyle: "italic" }}>
                          {transactionModalData?.posted_date}
                        </div>
                      </div>
                    )}
                    {transactionModalData?.status_label !== "Active" && (
                      <div style={{ fontStyle: "italic" }}>
                        {"(" + transactionModalData?.status_label + ")"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="data-wrap">
                  <div data-dd-privacy="mask" className="modal-label">
                    Income Total
                  </div>
                  $
                  {formatNumberToMoneyFormat(
                    unitsDividedByMultiplier(
                      Number(transactionModalData?.income_total),
                      Number(transactionModalData?.units_multiplier)
                    )
                  )}
                </div>
              </div>
              <div className="right-side-modal">
                <div className="data-wrap">
                  <div className="modal-label">Income From Interest</div>
                  <div>{transactionModalData?.type_label}</div>
                  <div>{transactionModalData?.friendly_id}</div>
                </div>
              </div>
            </div>

            <Table className="primary investment-table transactions-modal">
              <CellHeader className="table-header">
                <RawCell className="black-bold">Ownership</RawCell>
                <RawCell className="black-bold">Ownership in Period</RawCell>
                <RawCell className="black-bold">Rate</RawCell>
                <RawCell className="black-bold">Income Amount</RawCell>
                <RawCell></RawCell>
              </CellHeader>

              {transactionModalData?.ownership_certificates?.map(
                (ownership_certificate: any, index: any) => (
                  <BasicRow key={index}>
                    <TextCell key={index + "-percent"}>
                      <div style={{ fontWeight: "normal" }}>
                        {ownership_certificate.percent.toFixed(2)}%
                      </div>
                    </TextCell>
                    <TextCell key={index + "-period"}>
                      <span style={{ whiteSpace: "nowrap", color: "unset" }}>
                        {ownership_certificate.date}
                      </span>
                    </TextCell>
                    <TextCell key={index + "-rate"}>
                      {formatNumber(ownership_certificate?.rate)}%
                    </TextCell>
                    <TextCell className="right" key={index + "-amount"}>
                      <span data-dd-privacy="mask">
                        $
                        {formatNumberToMoneyFormat(
                          unitsDividedByMultiplier(
                            ownership_certificate.total_amount,
                            100
                          ) ?? 0
                        )}
                        {ownership_certificate.type === "detailed" && (
                          <>
                            {" (+"}$
                            {formatNumberToMoneyFormat(
                              unitsDividedByMultiplier(
                                ownership_certificate.amount,
                                100
                              ) ?? 0
                            )}
                            {")"}
                          </>
                        )}
                      </span>
                    </TextCell>
                  </BasicRow>
                )
              )}
              <BasicRow key={"total"} rowClasses={["table-total"]}>
                <TextCell key={"total-percent"}></TextCell>
                <TextCell key={"total-period"}></TextCell>
                <TextCell key={"total-rate"}></TextCell>
                <TextCell className="right" key={"total-amount"}>
                  $
                  {formatNumberToMoneyFormat(
                    unitsDividedByMultiplier(
                      Number(transactionModalData?.income_total),
                      Number(transactionModalData?.units_multiplier)
                    )
                  )}
                </TextCell>
              </BasicRow>
            </Table>

            <ActionButton
              id={"close-modal"}
              key={"close-purchase-order-modal"}
              callback={closeIsDetailsOpen}
            >
              Close
            </ActionButton>
          </div>
        </div>
      </KratosModal>
      <div className="column">
        <div className="full">
          <div className="column transaction">
            <DateComponent
              id="date_from"
              dateFormat={"Y-m-d"}
              value={dateFrom}
              onChange={setDateFrom}
              columnStyle={"date"}
              key="date_from"
              label="From"
            />
            <DateComponent
              id="date_to"
              dateFormat={"Y-m-d"}
              value={dateTo}
              onChange={setDateTo}
              columnStyle={"date"}
              key="date_to"
              label="To"
              minDate={dateFrom ?? null}
            />
            <div className="column button">
              <ActionButton
                key="download-csv"
                title="Download as CSV file"
                callback={downloadCsvCallback}
                disabled={
                  transactions?.data === undefined ||
                  transactions?.data?.length === 0
                }
              >
                <FontAwesomeIcon icon="arrow-down" style={{ marginRight: 4 }} />
                CSV
              </ActionButton>
              {initiateDownload && (
                <CSVDownload
                  filename={csvFileName}
                  data={csvData}
                  headers={headers}
                  target="_blank"
                />
              )}
            </div>
            <div className="column date-select hide-on-mobile">
              <SelectComponent
                id="quick_date_range"
                key="quick-date-range"
                defaultValue=""
                columnStyle={"no-padding right"}
                onChange={setRange}
              >
                <option disabled value="">
                  Quick Date Range
                </option>
                {quick_date_range_options}
              </SelectComponent>
            </div>
          </div>
        </div>
        <Table
          showSearchBar={false}
          title=""
          emptyText="Empty"
          isEmpty={
            transactions?.data === undefined || transactions?.data?.length === 0
          }
        >
          <CellHeader className={"contact-detail-header section-header"}>
            <RawCell>Date</RawCell>
            <RawCell>Effective Date</RawCell>
            <RawCell>Type</RawCell>
            <RawCell>Description</RawCell>
            <RawCell className="right">Amount</RawCell>
            <RawCell></RawCell>
          </CellHeader>
          {transactionData?.data?.map((transaction: any) => (
            <BasicRow
              key={transaction.uuid}
              rowClasses={[
                transaction?.status !== "active" ? "transaction-pending" : "",
              ]}
            >
              <TextCell key={transaction.uuid + "-date"}>
                <div>{transaction?.posted_date}</div>
              </TextCell>
              <TextCell>
                <div>{transaction?.effective_date}</div>
              </TextCell>
              <TextCell key={transaction.uuid + "-type"}>
                {transaction.description.startsWith("Revert ") === true
                  ? "Revert "
                  : ""}
                {transaction.type_label}
              </TextCell>
              <TextCell
                key={transaction.uuid + "-description"}
                className="pre-line"
              >
                {transaction.description}
              </TextCell>
              <TextCell
                key={transaction.uuid + "-amount"}
                className={
                  "right " +
                  (transaction.debit_amount !== null
                    ? "red-important"
                    : "black-important")
                }
              >
                <span data-dd-privacy="mask">
                  {transaction.credit_amount === null ? "(" : ""}$
                  {formatNumberToMoneyFormat(
                    unitsDividedByMultiplier(
                      transaction?.debit_amount ?? transaction?.credit_amount,
                      100
                    ) ?? 0
                  )}
                  {transaction?.credit_amount === null ? ")" : ""}
                </span>
              </TextCell>
              {transaction?.type_label === "Interest Income" ? (
                <ActionButtonsCell>
                  <ActionButton
                    id={"view-" + transaction?.uuid}
                    key={"view-" + transaction?.uuid}
                    title="View Transaction"
                    callback={() => updateModalData(transaction?.uuid)}
                  >
                    Details
                  </ActionButton>
                </ActionButtonsCell>
              ) : (
                <RawCell />
              )}
            </BasicRow>
          ))}
        </Table>
        <div className="pagination-wrap">
          {transactionData?.data?.length > 0 && (
            <KratosPagination
              page={page}
              total_pages={totalPages}
              paginationCallback={handlePagination}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Transactions;
