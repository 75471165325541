import React, { FC, FormEvent, useCallback, useEffect, useState } from "react";

import {
  Column,
  Container,
  DateComponent,
  MoneyComponent,
  SelectComponent,
  utils,
} from "@truenorthmortgage/olympus";
import { SERVICES } from "../../../services";
import { InvestmentAccountService } from "../../../services/investment-account-service";
import {
  calcEffectiveInvestorRate,
  convertMoneyStringToInteger,
  formatDateToLocalString,
  formatDateYYYYMMDD,
  formatNumberToMoneyFormat,
  notify,
  notifyErrors,
  removeTrailingDecimals,
  unitsDividedByMultiplier,
} from "../../../utils";
import Loading from "../../Loading";
import {
  ActionQueueCancelForm,
  ActionQueuePurchaseForm,
  DefaultResponseRequest,
  PurchaseAccountResponse,
} from "../../../models/schemas/request-schema";
import { ERROR } from "../../../constants";
import { ActionQueueService } from "../../../services/action-queue-service";
import { useNavigate } from "react-router-dom";
export type PurchaseModalProps = {
  callback?: any;
  investment_listing_object?: any;
  account_info?: any;
  cancel_request?: any;
  action_queue_purchase_object?: any;
};

const PurchaseModal: FC<PurchaseModalProps> = ({
  callback = null,
  investment_listing_object = null,
  account_info = null,
  cancel_request = null,
  action_queue_purchase_object = null,
}) => {
  const navigate = useNavigate();

  const investmentAccountService =
    utils.injection.useInjection<InvestmentAccountService>(
      SERVICES.InvestmentAccountService
    );

  const actionQueueService = utils.injection.useInjection<ActionQueueService>(
    SERVICES.ActionQueueService
  );

  useEffect(() => {
    investmentAccountService
      .getInvestmentAccounts()
      .then((data) => {
        // Assuming data is an object like { investment_accounts: any }
        setInvestorData(data?.investment_accounts);
      })
      .catch(() => {});
  }, []);

  const [loading, setLoading] = useState(false);
  const [investorData, setInvestorData] = useState<InvestorData>({});
  const [investmentAccountUUID, setInvestmentAccountUUID] = useState(
    account_info?.uuid
  );
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [amount, setAmount] = useState(action_queue_purchase_object?.amount);
  const [requestedDate, setRequestedDate] = useState(
    action_queue_purchase_object?.transaction_date
  );
  const [investmentListingData, setInvestmentListingData] = useState(
    investment_listing_object
  );
  const [investmentRate, setInvestmentRate] = useState("");

  const [errors, setErrors] = useState({
    from_investment_account_uuid: false,
    request_purchase_date: false,
    purchase_amount: false,
  });

  interface InvestorData {
    [key: string]: {
      uuid: string;
      user_nickname: string;
      friendly_id: string;
      created_at: string;
      updated_at: string;
      type: string;
      investor_account_uuid: string;
      status: string;
      morcado_id: string;
      preferred_auto_withdraw_bank_account_uuid: string | null;
      minimum_purchase_amount_required: string;
      current_balance: number;
    };
  }

  const findInvestmentAccount = (uuid: string) => {
    // Access the object directly using the UUID as the key
    const investmentAccount = investorData[uuid];

    // Set the minimum required !
    if (investmentAccount) {
      setMinimumAmount(
        Number(investmentAccount?.minimum_purchase_amount_required)
      );
      return investmentAccount;
    } else {
      return null;
    }
  };

  const investmentAccountCallback = (val: any) => {
    const foundInvestmentAccount = findInvestmentAccount(val);
    if (foundInvestmentAccount && foundInvestmentAccount.uuid) {
      // Only call setInvestmentAccountUUID if foundInvestmentAccount and its uuid property are defined
      setInvestmentAccountUUID(foundInvestmentAccount.uuid);
    }
  };

  const dateCallback = (val: any) => {
    if (val) {
      setRequestedDate(val);
    }
  };

  const submitCancelRequest = useCallback(
    (event: FormEvent) => {
      const form = {
        action_queue_uuid: action_queue_purchase_object?.uuid,
      } as ActionQueueCancelForm;
      event.preventDefault();
      setLoading(true);
      actionQueueService
        .cancelActionQueueRequest(form)
        .then((data: DefaultResponseRequest) => {
          if (true === data.success) {
            notify(data.message as string, "success");
            callback();
          } else {
            if (data.errors) {
              if (data?.errors?.request_processed === true) {
                callback();
              }
            }
            if (data.message) {
              notify(data.message as string, "error");
            }
            setErrors(data.errors);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [actionQueueService, action_queue_purchase_object]
  );

  const submitPurchaseRequest = useCallback(
    (event: FormEvent) => {
      const form = {
        purchase: {
          from_investment_account_uuid: investmentAccountUUID ?? null,
          investment_listing_uuid: investment_listing_object?.listing?.uuid,
          amount: amount ? amount.toString() : 0,
          request_purchase_date: requestedDate,
        },
      } as ActionQueuePurchaseForm;

      event.preventDefault();
      setLoading(true);
      actionQueueService
        .sendPurchaseRequest(form)
        .then((data: PurchaseAccountResponse) => {
          if (true === data.success) {
            notify(data.message as string, "success");
            navigate("/investment-account/" + investmentAccountUUID);
          } else {
            if (data.errors) {
              notifyErrors(data.errors);
            }
            if (data.message) {
              notify(data.message as string, "error");
            }
            setErrors(data.errors);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [
      actionQueueService,
      investmentAccountUUID,
      amount,
      requestedDate,
      investment_listing_object,
    ]
  );

  useEffect(() => {
    setInvestmentRate(
      calcEffectiveInvestorRate(
        false,
        Number(investment_listing_object?.investment?.interest_rate),
        Number(investment_listing_object?.investment?.servicing_rate),
        Number(investment_listing_object?.listing?.return_rate_modifier_bps)
      )
    );
  }, [investment_listing_object]);

  useEffect(() => {
    if (cancel_request === true) {
      setAmount(action_queue_purchase_object?.amount);
      setRequestedDate(action_queue_purchase_object?.transaction_date);
      setInvestmentAccountUUID(
        action_queue_purchase_object?.investment_account_to_purchase_from_uuid
      );
      setInvestmentListingData(
        action_queue_purchase_object?.investment_listing
      );
      setMinimumAmount(
        action_queue_purchase_object?.minimum_purchase_amount_required
      );
      setInvestmentRate(
        calcEffectiveInvestorRate(
          false,
          Number(action_queue_purchase_object?.investment?.interest_rate),
          Number(action_queue_purchase_object?.investment?.servicing_rate),
          Number(
            action_queue_purchase_object?.investment_listing
              ?.return_rate_modifier_bps
          )
        )
      );
    }
  }, [action_queue_purchase_object, cancel_request]);

  return (
    <>
      <Container
        className={
          "generic-modal-container action-queue-fund-container not-allowed-to-withdraw"
        }
      >
        <Column columnStyle={"full no-padding-left"}>
          <div className="center">
            <h2>Listing Purchase Request</h2>
          </div>
        </Column>

        <Column columnStyle={"full no-padding-left no-padding-bottom"}>
          <span className={"grey-text"} style={{ fontWeight: "700" }}>
            Listing Details
          </span>
        </Column>

        {/*// One Row*/}
        <Column columnStyle={"flex-column no-left-padding no-padding-bottom"}>
          <Column columnStyle="side-by-side-element no-padding-bottom">
            <Column columnStyle="side-by-side-element no-left-padding  no-padding-bottom">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>ID</p>
                </div>
                <div className={"right location-purchase"}>
                  <p>
                    {investment_listing_object?.listing?.friendly_id ??
                      action_queue_purchase_object?.investment_listing
                        ?.friendly_id}
                  </p>
                </div>
              </div>
            </Column>
          </Column>
          <Column columnStyle="side-by-side-element no-padding-bottom">
            <Column columnStyle="side-by-side-element no-left-padding no-padding-bottom">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Property Use</p>
                </div>
                <div className={"right"}>
                  <p style={{ textTransform: "capitalize" }}>
                    {investment_listing_object?.property?.property_use ??
                      action_queue_purchase_object?.property?.property_use}
                  </p>
                </div>
              </div>
            </Column>
          </Column>
        </Column>

        {/*// One Row*/}
        <Column columnStyle={"flex-column no-left-padding no-padding-bottom"}>
          <Column columnStyle="side-by-side-element no-padding-bottom">
            <Column columnStyle="side-by-side-element no-left-padding no-padding-bottom ">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Grade</p>
                </div>
                <div className={"right  location-purchase"}>
                  <div style={{ display: "flex" }}>
                    <span
                      style={{ marginRight: "auto" }}
                      className={
                        "purchase-grade-small " +
                        "grade grade-" +
                        (investment_listing_object?.investment?.investment_grade.toLowerCase() ??
                          action_queue_purchase_object?.investment?.investment_grade.toLowerCase())
                      }
                    >
                      <div className="inner-wrap">
                        <span
                          className={
                            "grade-" +
                            (investment_listing_object?.investment?.investment_grade.toLowerCase() ??
                              action_queue_purchase_object?.investment?.investment_grade.toLowerCase())
                          }
                        >
                          <i
                            className="fa fa-circle background-circle-fa"
                            style={{ marginLeft: "0px" }}
                          >
                            <span>
                              {investment_listing_object?.investment?.investment_grade.toLowerCase() ??
                                action_queue_purchase_object?.investment?.investment_grade.toLowerCase()}
                            </span>
                          </i>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </Column>
          </Column>
          <Column columnStyle="side-by-side-element no-padding-bottom">
            <Column columnStyle="side-by-side-element no-left-padding no-padding-bottom">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Rate</p>
                </div>
                <div className={"right"}>
                  {/* Double check the interest rate */}
                  <p>{investmentRate}%</p>
                </div>
              </div>
            </Column>
          </Column>
        </Column>

        {/*// One Row*/}
        <Column columnStyle={"flex-column no-left-padding no-padding-bottom"}>
          <Column columnStyle="side-by-side-element no-padding-bottom">
            <Column columnStyle="side-by-side-element no-left-padding no-padding-bottom">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Location</p>
                </div>
                <div className={"right location-purchase"}>
                  <p>
                    {investment_listing_object?.property?.neighbourhood ??
                      action_queue_purchase_object?.property?.neighbourhood}
                    ,{" "}
                    {investment_listing_object?.property?.city ??
                      action_queue_purchase_object?.property?.city}
                    ,{" "}
                    {investment_listing_object?.property?.province ??
                      action_queue_purchase_object?.property?.province}
                  </p>
                </div>
              </div>
            </Column>
          </Column>
          <Column columnStyle="side-by-side-element no-padding-bottom">
            <Column columnStyle="side-by-side-element no-left-padding no-padding-bottom">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Renewal Date</p>
                </div>
                <div className={"right"}>
                  <p>
                    {formatDateYYYYMMDD(
                      investment_listing_object?.investment?.maturity_date
                    ) ??
                      formatDateToLocalString(
                        action_queue_purchase_object?.investment_details
                          ?.maturity_date
                      )}
                  </p>
                </div>
              </div>
            </Column>
          </Column>
        </Column>

        <Column columnStyle={"flex-column no-left-padding"}>
          <Column columnStyle="side-by-side-element no-bottom-padding">
            <Column columnStyle="side-by-side-element no-left-padding ">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Security</p>
                </div>
                <div className={"right location-purchase"}>
                  <p style={{ textTransform: "capitalize" }}>
                    {investment_listing_object?.investment?.security ??
                      action_queue_purchase_object?.investment_details
                        ?.security}
                  </p>
                </div>
              </div>
            </Column>
          </Column>
          <Column columnStyle="side-by-side-element">
            <Column columnStyle="side-by-side-element no-left-padding">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Amount Available</p>
                </div>
                <div className={"right"}>
                  <p>
                    $
                    {formatNumberToMoneyFormat(
                      unitsDividedByMultiplier(
                        investment_listing_object?.listing
                          ?.units_available_to_be_sold ?? 0,
                        action_queue_purchase_object?.investment_listing
                          ?.units_multiplier ?? 1
                      )
                    )}
                  </p>
                </div>
              </div>
            </Column>
          </Column>
        </Column>

        <Column columnStyle={"flex-column no-left-padding"}>
          <Column columnStyle="side-by-side-element no-bottom-padding no-left-padding ">
            <Column columnStyle="side-by-side-element no-left-padding ">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Weighted LTV</p>
                </div>
                <div className={"right location-purchase"}>
                  <p style={{ paddingLeft: "5px" }}>
                    {removeTrailingDecimals(
                      investment_listing_object?.investment_details
                        ?.weighted_ltv
                    ) ??
                      removeTrailingDecimals(
                        action_queue_purchase_object?.investment_details
                          ?.weighted_ltv
                      )}
                    %
                  </p>
                </div>
              </div>
            </Column>
          </Column>
        </Column>

        <Column columnStyle={"flex-column no-left-padding"}>
          <Column columnStyle="side-by-side-element no-bottom-padding no-left-padding ">
            <Column columnStyle="side-by-side-element no-left-padding ">
              <div className={"flex-wrap"}>
                <div className={"left"}>
                  <p>Credit Score</p>
                </div>
                <div className={"right location-purchase"}>
                  <div className="score-wrap inline">
                    <i className="fa-solid fa-signal-bars-good"></i>{" "}
                    <span className="credit-span">
                      {investment_listing_object === null ? (
                        <>
                          {Array.isArray(
                            action_queue_purchase_object?.borrowers
                          ) === true &&
                          action_queue_purchase_object?.borrowers[0]
                            ?.credit_score
                            ? action_queue_purchase_object?.borrowers[0]
                                ?.credit_score
                            : action_queue_purchase_object?.borrowers
                                ?.credit_score}
                        </>
                      ) : (
                        <>
                          {Array.isArray(
                            investment_listing_object?.borrowers
                          ) === true &&
                          investment_listing_object?.borrowers[0]?.credit_score
                            ? investment_listing_object?.borrowers[0]
                                ?.credit_score
                            : ""}
                        </>
                      )}
                      +
                    </span>
                  </div>
                </div>
              </div>
            </Column>
          </Column>
        </Column>

        <Column
          columnStyle={"flex-column no-left-padding purchase-input-columns"}
        >
          <Column columnStyle="side-by-side-element no-bottom-padding no-left-padding-purchase">
            <SelectComponent
              label="From Investment Account"
              id="from_investment_account"
              key="from_investment_account"
              className={"investment-account-select"}
              onChange={investmentAccountCallback}
              value={investmentAccountUUID}
              error={errors?.from_investment_account_uuid}
              disabled={
                (loading ? true : false) || (cancel_request ? true : false)
              }
            >
              <option value="select">Select Account</option>
              {Object.entries(investorData).map(
                ([key, value]: [string, any]) => (
                  <React.Fragment key={"main-row" + value?.uuid}>
                    <option
                      id={value?.uuid}
                      key={value?.uuid}
                      value={value?.uuid}
                    >
                      {(
                        value?.type +
                        " " +
                        value?.morcado_id +
                        " - " +
                        "$" +
                        formatNumberToMoneyFormat(
                          unitsDividedByMultiplier(value?.current_balance, 100)
                        )
                      ).toUpperCase()}
                    </option>
                  </React.Fragment>
                )
              )}
            </SelectComponent>
          </Column>
          <Column columnStyle="side-by-side-element no-bottom-padding no-left-padding-purchase">
            <label>Purchase Amount</label>
            <MoneyComponent
              id="purchase_amount"
              key="purchase_amount"
              onChange={setAmount}
              error={errors?.purchase_amount}
              defaultValue={amount}
              disabled={
                (loading ? true : false) || (cancel_request ? true : false)
              }
            />
          </Column>
        </Column>

        <Column columnStyle={"flex-column"}>
          <Column columnStyle="side-by-side-element no-left-padding-date-component">
            <label style={{ marginTop: "10px" }}>
              Requested Transaction Date
            </label>
            <div className={"date-icon-wrap"}>
              <i className="fa fa-calendar"></i>
              <DateComponent
                id="requested_transaction_date"
                key="requested_transaction_date"
                className={"no-left-padding"}
                minDate={
                  cancel_request
                    ? new Date(new Date(requestedDate).setHours(0, 0, 0, 0))
                    : new Date(new Date().setHours(0, 0, 0, 0))
                }
                onChange={dateCallback}
                defaultValue={requestedDate}
                value={requestedDate}
                error={errors?.request_purchase_date}
                disabled={
                  (loading ? true : false) || (cancel_request ? true : false)
                }
              />
            </div>
          </Column>
          <Column columnStyle="side-by-side-element no-bottom-padding no-left-padding-purchase note-top-padding">
            <p style={{ paddingTop: "25px" }}>
              Note: A minimum purchase of $
              {formatNumberToMoneyFormat(
                unitsDividedByMultiplier(minimumAmount, 100)
              )}{" "}
              is required from this investment account type.
            </p>
          </Column>
        </Column>

        {cancel_request === true ? (
          <div></div>
        ) : (
          <>
            <Column
              columnStyle={"flex-column no-left-padding purchase-input-columns"}
            >
              <div className={"info-container"}>
                All fields are required. We endeavour to process purchases on
                your requested purchase date, but cannot guarantee the purchase
                date.
              </div>
            </Column>
            <Column
              columnStyle={"flex-column no-left-padding purchase-input-columns"}
            >
              <div className={"info-container"}>
                If you wish to make a purchase of an amount less than the
                minimum shown here, please contact us at{" "}
                <a href={"info@morcado.ca"}>info@morcado.ca</a> for assistance.
              </div>
            </Column>
          </>
        )}

        {cancel_request === true ? (
          <Column columnStyle="flex-column small-top-margin no-left-padding no-right-padding">
            <button
              key="cancel_fund_request"
              type="button"
              className="button submit-button no-right-margin"
              style={{ marginRight: "auto" }}
              onClick={callback}
            >
              <i
                style={{ marginRight: "10px" }}
                className="fa-solid fa-chevron-left"
              ></i>
              Back
            </button>

            <button
              key="send_fund_request"
              type="button"
              className="button submit-button no-right-margin button-red"
              style={{ marginLeft: "auto" }}
              onClick={submitCancelRequest}
              disabled={loading ? true : false}
            >
              {loading === true ? (
                <Loading
                  style={{
                    height: 40,
                    marginTop: -30,
                    position: "relative",
                    top: 14,
                  }}
                  color={"white"}
                />
              ) : (
                " Cancel Request"
              )}
            </button>
          </Column>
        ) : (
          <Column columnStyle="flex-column small-top-margin no-left-padding no-right-padding">
            <button
              key="cancel_fund_request"
              type="button"
              className="button submit-button no-right-margin button-red"
              onClick={callback}
              style={{ marginRight: "auto" }}
            >
              Cancel
            </button>

            <button
              key="send_fund_request"
              type="button"
              className="button submit-button no-right-margin button-green"
              style={{ marginLeft: "auto" }}
              onClick={submitPurchaseRequest}
            >
              {loading === true ? (
                <Loading
                  style={{
                    height: 40,
                    marginTop: -30,
                    position: "relative",
                    top: 14,
                  }}
                  color={"white"}
                />
              ) : (
                " Send Request"
              )}
            </button>
          </Column>
        )}
      </Container>
    </>
  );
};

export default PurchaseModal;
