import { pushNotification, store, utils } from "@truenorthmortgage/olympus";
import { useNavigate } from "react-router-dom";

export const parseError = (error: any) => {
  if (error) {
    let errorString = "Please check inputs";
    for (const match of error.message.matchAll(
      /Variable "(?<field>.*)": (?<message>.*)\./g
    )) {
      errorString += `\n\n${match.groups.field}: ${match.groups.message}`;
    }

    return errorString;
  }
};

export const handleQueryError = (
  error: any,
  message: string,
  setErrorFunc?: any
) => {
  if (window.location.pathname !== "/login" && error.message.match("401")) {
    sessionStorage.removeItem("thinkToken");
    notify("Please log back in", "error");
    window.location.replace("/login");
  } else if (error.message.match("503")) {
    notify(
      "The server is currently unavailable. Please try again later.",
      "error"
    );
  } else {
    if (setErrorFunc) {
      setErrorFunc(message);
    } else {
      notify(message, "error");
    }
  }
};

export const removeTrailingDecimals = (num: number) => {
  if (num === null || num === undefined) {
    return null;
  }

  return Math.floor(num); // or Math.trunc(num) if you want to truncate instead of rounding down
};

export const notify = (message: string, className = "success") => {
  store.dispatch(
    pushNotification({
      message,
      class: className,
    })
  );
};

export const notifyErrors = (errors: any) => {
  if (Object.keys(errors).length > 0) {
    Object.keys(errors).map((attr: string) => {
      if (false === Array.isArray(errors[attr])) {
        const message = errors[attr];
        store.dispatch(
          pushNotification({
            message,
            class: "error",
          })
        );
      } else {
        const message = errors[attr][0];

        store.dispatch(
          pushNotification({
            message,
            class: "error",
          })
        );
      }
    });
  }
};

export const valuesToInt = (params: any, keys: string[]) => {
  const newParams = { ...params };

  Object.keys(newParams).forEach((key) => {
    if (keys.includes(key)) {
      const val = newParams[key]
        .replace("$", "")
        .replace(/,/g, "")
        .replace(".", "");

      if (!val || isNaN(val)) return;
      newParams[key] = parseInt(val);
    }
  });
  return newParams;
};

export const valuesToDate = (params: any, keys: string[]) => {
  const newParams = { ...params };
  Object.keys(newParams).forEach((key) => {
    if (keys.includes(key)) {
      if (!newParams[key]) return;
      newParams[key] = utils.date.dbDate(newParams[key]);
    }
  });
  return newParams;
};

export const formatDateToLocalString = (date: any) => {
  if (date === null || date === undefined) {
    return null;
  }

  const dateFormat = new Date(date);
  return dateFormat.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

/**
 * Format the date yo Y-m-d
 *
 * @param date
 */
export const formatDateYYYYMMDD = (date: any) => {
  if (date === null || date === undefined) {
    return null;
  }

  const dateFormat = new Date(date);

  const year: number = dateFormat.getFullYear();
  const month: string = String(dateFormat.getMonth() + 1).padStart(2, "0");
  const day: string = String(dateFormat.getDate()).padStart(2, "0");

  return year.toString() + "-" + month.toString() + "-" + day.toString();
};

export const dateToYYYYMMDD = (dateString: string): string => {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Calculate the units based off of the units multiplier
 *
 * eg 100 units should be stored as 10000
 *
 * @param units
 * @param units_multiplier
 */
export const unitsMultipliedByMultiplier = (
  units: number,
  units_multiplier: number
) => {
  if (units === 0) {
    return 0;
  } else {
    return Number(units * units_multiplier);
  }
};

/**
 * Calculate the units divided by the units multiplier
 *
 * eg 10000 should be 100 on the views
 *
 * @param units
 * @param units_multiplier
 */
export const unitsDividedByMultiplier = (
  units: number,
  units_multiplier: number
) => {
  if (units === 0) {
    return 0;
  } else {
    return Number(units / units_multiplier);
  }
};

/**
 * Format the number to 999,999,999,999.99 format.
 *
 * @param number
 * @param decimalDigits
 */
export const formatNumberToMoneyFormat = (
  number: number,
  decimalDigits = 2
) => {
  if (isNaN(number) === true || isNaN(decimalDigits) === true) {
    return "0.00";
  }
  if (!number) {
    return "0.00";
  }
  return Number(number)
    .toFixed(decimalDigits)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const formatNumber = (units: number) => {
  units = isNaN(units) === false ? units : 0;
  if (Number.isInteger(units)) {
    return units.toLocaleString(undefined, { useGrouping: true });
  }
  return formatNumberToMoneyFormat(units);
};

/**
 * Update the Pagination
 *
 * @param page_value
 * @param page
 * @param url
 */
export const updatePagination = (page_value: any, page: any, url: string) => {
  const navigate = useNavigate();

  if (page_value) {
    if (url.includes("page=")) {
      const pattern = /page=([0-9]+)/gm;
      const currentUrl = url;
      const newUrl = currentUrl.replace(pattern, "page=" + page_value);
      navigate(newUrl);
    } else {
      if (page_value == page) {
        return;
      }
      const pagination = (url.includes("?") ? "&" : "?") + "page=" + page_value;
      navigate(url + pagination);
    }
  }
};

export const getPercent = (value: number, total: number, round = true) => {
  if (isNaN(value) === true || isNaN(value) === true) {
    return (0.0).toFixed(2);
  }
  if (value <= 0) {
    return (0.0).toFixed(2);
  }
  if (total <= 0) {
    return (0.0).toFixed(2);
  }

  let percent: number;

  if (round === false) {
    percent = (value / total) * 100;
  } else {
    percent = Math.round((value / total) * 100);
  }
  return percent > 100 ? 100.0 : percent.toFixed(2);
};

/**
 * Effective Investor Rate = investment's interest rate - investment's servicing rate + the Return Rate Modifier
 *
 * @param error
 * @param investmentInterestRate
 * @param investmentServicingRate
 * @param returnRateModifier
 */
export const calcEffectiveInvestorRate = (
  error: any,
  investmentInterestRate: any,
  investmentServicingRate: any,
  returnRateModifier: any
) => {
  const interestMinusServicing =
    investmentInterestRate - investmentServicingRate;
  const finalInterest = interestMinusServicing + returnRateModifier;
  return parseFloat(finalInterest).toFixed(2);
};

/**
 * Scrambles a number to hide certain values
 *
 * @param number
 */
export const scrambleNumber = (number: any) => {
  if (number) {
    const returnNumber = [...number];
    const startToShowNumbers = number.length - 4;

    for (let i = 0; i < number.length; i++) {
      if (i < startToShowNumbers) {
        returnNumber[i] = "*";
      }
    }

    return returnNumber.toString().replace(/,/g, "");
  }

  return null;
};

/**
 * Calculate the investor rate
 *
 * @param interestRate
 * @param servicingRate
 */
export const calcInvestorRate = (interestRate: any, servicingRate: any) => {
  return (interestRate - servicingRate).toFixed(2);
};

/**
 * Format a money string to a integer
 *
 * @param moneyString
 */
export const convertMoneyStringToInteger = (moneyString: any) => {
  return Number(moneyString.replace(/[$,]/g, ""));
};

/**
 * Convert from UTC to local
 *
 * @param datetime
 */
export const convertFromUTCtoLocalTime = (datetime: string) => {
  let utc_date = new Date();

  if (datetime.length === 10) {
    utc_date = new Date(datetime + "T00:00:00Z");
  } else if (datetime.length === 19) {
    utc_date = new Date(datetime.replace(" ", "T") + "Z");
  } else {
    throw new Error("Invalid date or datetime");
  }

  const user_timezone: string =
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const local_datetime = utc_date.toLocaleString("en-US", {
    timeZone: user_timezone,
  });

  return local_datetime;
};

/**
 * Send a string in the format we want to show
 *
 * @param updated_at
 */
export const getFormattedDate = (updated_at: string | null = null) => {
  let today: Date = new Date();

  if (updated_at !== null) {
    today = new Date(updated_at);
  }

  // Get day and year
  const day: number = today.getDate();
  const year: number = today.getFullYear();
  const month: number = today.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Get time
  const time: string = today.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // Construct formatted date string
  const formatted_date = `${year}-${formattedMonth}-${day} ${time}`;

  return formatted_date;
};

export const formatDateFromString = (dateString: string) => {
  const monthsList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Split the date string into components
  const [year, month, day] = dateString.split(" ")[0].split("-");

  // Create a Date object with the parsed components
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  // Format the date
  const formattedDate = `${
    monthsList[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;

  return formattedDate;
};
